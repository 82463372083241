import { getTheme as getUiKitTheme } from '@urbaninfrastructure/react-ui-kit'
import { ThemeKey } from '@urbaninfrastructure/design-tokens'
import { DefaultTheme } from 'styled-components'

type FontSize = number
type _FontSizes = Partial<{ body: FontSize }> & FontSize[]
type FontSizes = { body: FontSize } & FontSize[]

export function getTheme({
  colorKey,
  isApp,
}: {
  colorKey?: ThemeKey
  isApp: boolean | null
}): DefaultTheme {
  const uiKitTheme = getUiKitTheme(colorKey)

  const footer = {
    color: uiKitTheme.colors.white,
    backgroundColor: uiKitTheme.colors.text,
  }

  // merge ui kit theme with public web theme
  let theme = {
    ...uiKitTheme,
    colors: { ...uiKitTheme.colors },
    fonts: { ...uiKitTheme.fonts },
    // FIXME: Move this to react-ui-kit?
    sizes: { xs: '600px', sm: '800px', md: '1000px', lg: '1400px' },
    radii: { ...uiKitTheme.radii, lg: '10px' },
    footer,
  }

  switch (colorKey) {
    case 'bikeMi': {
      theme.colors.primaryIcon = theme.colors.primaryDark = theme.colors.primaryText = theme.colors.text =
        'black'
      break
    }
    case 'edinburghCityBike': {
      const fontFamily = "'Open Sans', Helvetica, sans-serif"
      theme.fonts.sansSerif = theme.fonts.heading = theme.fonts.button = fontFamily
      theme.colors.link = theme.colors.text
      break
    }
    case 'pink02': {
      theme.footer.backgroundColor = '#4C3084'
      break
    }
    default: {
      break
    }
  }

  // override default theme if viewing from app
  if (isApp) {
    const _fontSizes: _FontSizes = [14, 16, 19, 26, 40, 56, 65, 85]
    _fontSizes.body = _fontSizes[2]
    const fontSizes = _fontSizes as FontSizes
    theme = {
      ...theme,
      fontSizes,
      textSize: 14,
    }
  }

  return theme
}
