import React from 'react'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/react-hooks'
import { Alert, BaseStyles } from '@urbaninfrastructure/react-ui-kit'
import { useSettingsQuery } from '../../lib/core'
import initApollo from '../../lib/init-apollo'
import { isEdinburgh, getFormats, getFaviconKey } from '../../lib/util'
import { getTheme } from '../../lib/theme'
import ErrorPage from '../ErrorPage'
import { FirebaseConfig, SystemConfigClient } from '../../types'
import { IntlProvider } from 'react-intl'
import { useSystemConfig } from '../../lib/use-system-config'
import PageLoading from '../../components/Layout/PageLoading'
import { useLocale } from '../../lib/use-locale'
import { settings_system, settings_me, LanguageCode } from '../../core-types'
import { ToastProvider, Toasts } from '@urbaninfrastructure/toasts'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

const urbansharingAssetsPath = 'https://assets.urbansharing.design'

const Favicons = ({ faviconKey }: { faviconKey: string | undefined }) => {
  if (!faviconKey) {
    return null
  }

  const path = `${urbansharingAssetsPath}/v1/favicons/public-web/${faviconKey}`

  return (
    <Head>
      <link
        key="favicon-16"
        rel="icon"
        href={`${path}/favicon-16x16.png`}
        type="image/x-icon"
        sizes="16x16"
      />
      <link
        key="favicon-32"
        rel="icon"
        href={`${path}/favicon-32x32.png`}
        type="image/x-icon"
        sizes="32x32"
      />
      <link
        key={`apple-touch-icon`}
        rel="apple-touch-icon"
        href={`${path}/apple-touch-icon-180x180.png`}
        type="image/png"
        sizes="180x180"
      />
    </Head>
  )
}

const apolloClient = (
  systemId: string,
  defaultLocale: LanguageCode,
  tenantId: string,
  firebaseConfig: FirebaseConfig,
  initialState?: NormalizedCacheObject
) =>
  initApollo({
    connectToDevTools: true,
    systemId,
    defaultLocale,
    tenantId,
    firebaseConfig,
    initialState,
  })

function AppInApollo({ children }: { children: React.ReactNode }) {
  const systemConfig = useSystemConfig()
  const {
    locale,
    defaultLocale,
    loading: intlLoading,
    messages,
    setLocale,
  } = useLocale()
  const { data } = useSettingsQuery({
    onCompleted: (data) => {
      if (data && data.me && data.me.preferredLanguageCode) {
        setLocale(data.me.preferredLanguageCode)
      }
    },
  })
  const { colourKey } = systemConfig
  let system: settings_system | undefined
  let intercom: any
  let me: settings_me | undefined

  if (data) {
    if (data.system) {
      system = data.system
    }
    if (data.intercomSettings) {
      intercom = data.intercomSettings
    }
    if (data.me) {
      me = data.me
    }
  }

  if (intlLoading) {
    return <PageLoading />
  }

  const theme = getTheme({ colorKey: colourKey, isApp: false })
  const edinburgh = isEdinburgh(theme)
  const formats = getFormats(system && system.currency ? system.currency : '')

  const intlProps = {
    key: locale,
    locale: (me && me.preferredLanguageCode) || locale,
    defaultLocale,
    messages,
    formats,
  }

  // If the page that is visited is not in a language we support, show a 404
  if (
    system &&
    system.languageCodes &&
    locale &&
    !system.languageCodes.includes(locale)
  ) {
    return (
      <IntlProvider {...intlProps}>
        <ErrorPage
          statusCode={404}
          intercomId={intercom && intercom.appId}
          errorType="pageNotFound"
        />
      </IntlProvider>
    )
  }

  return (
    <IntlProvider {...intlProps}>
      <BaseStyles colorKey={colourKey} theme={theme}>
        <ToastProvider>
          <>
            <Favicons faviconKey={getFaviconKey(systemConfig)} />
            <Head>
              {edinburgh && (
                <link
                  key="ubuntu-font"
                  href="https://fonts.googleapis.com/css?family=Ubuntu"
                  rel="stylesheet"
                />
              )}
            </Head>
            {children}
            <Toasts />
          </>
        </ToastProvider>
      </BaseStyles>
    </IntlProvider>
  )
}

export default function ClientApp({
  systemConfig,
  children,
  initialApolloState,
}: {
  children: React.ReactNode
  systemConfig: SystemConfigClient
  initialApolloState?: NormalizedCacheObject
}) {
  if (!systemConfig.businessFirebaseTenantId) {
    return <Alert>systemConfig is missing businessFirebaseTenantId</Alert>
  }
  return (
    <ApolloProvider
      client={apolloClient(
        systemConfig.id,
        systemConfig.defaultLanguageCode,
        systemConfig.businessFirebaseTenantId,
        systemConfig.firebaseConfig,
        initialApolloState
      )}
    >
      <AppInApollo>{children}</AppInApollo>
    </ApolloProvider>
  )
}
