import React, { useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { Alert, BaseStyles, Flex } from '@urbaninfrastructure/react-ui-kit'
import * as Sentry from '@sentry/browser'
import PageLoading from '../components/Layout/PageLoading'
import { Gtag } from '../lib/gtag'
import { SystemConfigProvider } from '../lib/use-system-config'
import ClientApp from '../modules/ClientApp'
import { SystemConfigClient } from '../types'
import { LocaleProvider } from '../lib/use-locale'
import { useTenantAuth } from '../lib/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

if (typeof window !== 'undefined') {
  let sentryEnvironment =
    process.env.NODE_ENV === 'production' ? 'production' : 'development'
  if (window.location.href.includes('urbansharing.tech')) {
    sentryEnvironment = 'staging'
  }
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: sentryEnvironment,
    release: process.env.BUILD_ID || 'dev',
  })
}

interface UseSystemConfigState {
  loading: boolean
  systemConfig: SystemConfigClient | undefined
  error?: Error
}

function useSystemConfig(
  initialSystemConfig?: SystemConfigClient
): UseSystemConfigState {
  const [state, setState] = useState<UseSystemConfigState>({
    loading: !initialSystemConfig,
    systemConfig: initialSystemConfig,
  })
  useEffect(() => {
    if (state.systemConfig || state.error) {
      return
    }
    fetch('/api/system-config')
      .then((res) => res.json())
      .then((res: { systemConfig: SystemConfigClient } | undefined) => {
        const systemConfig = res && res.systemConfig
        if (systemConfig && !systemConfig.businessFirebaseTenantId) {
          throw new Error('systemConfig is missing businessFirebaseTenantId')
        }
        setState({
          loading: false,
          systemConfig,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error loading systemConfig', error)
        Sentry.captureException(error)
        setState({ loading: false, systemConfig: undefined, error })
      })
  }, [state.systemConfig, state.error])
  return state
}

function AppWithSystemConfig({
  children,
  initialApolloState,
  systemConfig,
  defaultMessages,
}: {
  children: React.ReactElement
  initialApolloState: NormalizedCacheObject
  systemConfig: SystemConfigClient | undefined
  defaultMessages: Record<string, string> | undefined
}) {
  const auth = useTenantAuth(null)
  const [, userLoading] = useAuthState(auth)
  const loading = userLoading && !initialApolloState
  return (
    <>
      {loading && <PageLoading />}
      {!loading && (
        <>
          {!systemConfig && <div>System not found</div>}
          {systemConfig && (
            <LocaleProvider
              defaultLocale={systemConfig.defaultLanguageCode}
              defaultMessages={defaultMessages}
            >
              <>
                <Gtag />
                <ClientApp
                  systemConfig={systemConfig}
                  initialApolloState={initialApolloState}
                >
                  {children}
                </ClientApp>
              </>
            </LocaleProvider>
          )}
        </>
      )}
    </>
  )
}

function MyApp({ Component, pageProps }: AppProps) {
  const { loading, systemConfig, error } = useSystemConfig(
    pageProps.initialSystemConfig
  )

  if (error) {
    return (
      <BaseStyles>
        <Flex alignItems="center" justifyContent="center" height="100vh">
          <Alert variant="error">Could not load system config</Alert>
        </Flex>
      </BaseStyles>
    )
  }

  return (
    <SystemConfigProvider systemConfig={systemConfig}>
      <BaseStyles
        colorKey={systemConfig?.colourKey}
        font={
          systemConfig?.id === 'edinburgh-city-bikes'
            ? undefined
            : 'urban-grotesk'
        }
      >
        {error && <Alert variant="error">Could not load system config</Alert>}
        {loading && <PageLoading />}
        {!loading && (
          <AppWithSystemConfig
            initialApolloState={pageProps.initialApolloState}
            defaultMessages={pageProps.defaultMessages}
            systemConfig={systemConfig}
          >
            <Component {...pageProps} />
          </AppWithSystemConfig>
        )}
      </BaseStyles>
    </SystemConfigProvider>
  )
}

export default MyApp
