import ApolloClient from 'apollo-client'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import * as Sentry from '@sentry/browser'
import { LOCALE_COOKIE_NAME } from './constants'
import { isServer } from './util'
import introspectionQueryResultData from './core/fragment-types.json'
import { GraphQLError } from 'graphql'
import { getAuth } from './firebase'
import { LanguageCode } from '../core-types'
import { FirebaseConfig } from '../types'
import type { IncomingMessage } from 'http'

function createIsomorphLink(
  req: IncomingMessage | undefined,
  systemId: string,
  locale: string
) {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { SchemaLink } = require('apollo-link-schema')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { schema } = require('../apollo/schema')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { getContext } = require('../apollo/context')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const CoreAPI = require('../apollo/datasources/core').default

    return new SchemaLink({
      schema,
      context: () => {
        const context = getContext(req, systemId, locale)
        context.dataSources = {
          coreAPI: new CoreAPI(),
        }
        Object.values(context.dataSources).forEach((dataSource: any) => {
          if (dataSource.initialize) {
            dataSource.initialize({
              context,
            })
          }
        })
        return context
      },
    })
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { HttpLink } = require('apollo-link-http')
    return new HttpLink({
      uri: '/api/graphql',
      credentials: 'same-origin',
    })
  }
}

type Options = {
  connectToDevTools?: boolean
  systemId: string
  defaultLocale: LanguageCode
  tenantId: string
  firebaseConfig: FirebaseConfig
  req?: IncomingMessage
  initialState?: NormalizedCacheObject
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

let apolloClient: ApolloClient<any> | null = null

interface CoreGraphQLError extends GraphQLError {
  code: string
}

const reportErrors = () =>
  onError((props) => {
    const { graphQLErrors, networkError, operation } = props
    if (graphQLErrors || networkError) {
      const ssr = isServer ? 'true' : 'false'
      if (graphQLErrors) {
        // @ts-ignore
        graphQLErrors.map((error: CoreGraphQLError) => {
          const { code, message } = error
          const errorMessage = `[GraphQL error]: ${code}: ${message}`
          Sentry.withScope((scope) => {
            scope.setFingerprint([
              'Type: GraphQLError',
              `Code: ${code}`,
              `Message: ${message}`,
            ])
            if (networkError) {
              scope.setExtra('networkError', networkError)
            }
            scope.setTag('ssr', ssr)
            Sentry.captureException(new Error(errorMessage))
          })
        })
      } else if (networkError) {
        let message = `[Network error]: ${networkError}`
        if (operation && operation.operationName) {
          message += `, operationName: ${operation.operationName}`
        }
        Sentry.withScope((scope) => {
          scope.setFingerprint(['Type: GraphQLNetworkError'])
          scope.setTag('ssr', ssr)
          Sentry.captureException(new Error(message))
        })
      }
    }
  })

function create({
  connectToDevTools,
  systemId,
  defaultLocale,
  tenantId,
  firebaseConfig,
  req,
}: Options) {
  const cache = new InMemoryCache({ fragmentMatcher })
  const auth = getAuth(tenantId, defaultLocale, firebaseConfig)

  const handleUnauthorized = () =>
    onError(({ graphQLErrors, networkError }) => {
      if (
        graphQLErrors &&
        graphQLErrors.find(
          (error) => error.extensions?.code === 'UNAUTHENTICATED'
        )
      ) {
        auth.signOut()
      } else if (
        networkError &&
        // @ts-ignore
        (networkError.statusCode === 401 || networkError.statusCode === 403)
      ) {
        auth.signOut()
        // redirect(nextContext, nextContext.asPath)
      }
    })

  const firebaseAuthLink = setContext((_, { headers }) => {
    //it will always get unexpired version of the token
    if (!auth.currentUser) {
      return { headers }
    }
    return auth.currentUser.getIdToken().then((token) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    })
  })

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      return {
        headers: {
          systemid: systemId,
          locale:
            (typeof window !== 'undefined' &&
              localStorage.getItem(LOCALE_COOKIE_NAME)) ||
            defaultLocale,
          ...headers,
        },
      }
    })
    return forward(operation)
  })

  return new ApolloClient<any>({
    connectToDevTools: !isServer || connectToDevTools,
    ssrMode: isServer, // Disables forceFetch on the server (so queries are only run once)
    // @ts-ignore
    link: ApolloLink.from([
      firebaseAuthLink,
      authLink,
      handleUnauthorized(),
      reportErrors(),
      createIsomorphLink(req, systemId, defaultLocale),
    ]),
    cache,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  })
}

export default function initApollo(options: Options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (isServer) {
    const serverApolloClient = create(options)
    if (options.initialState) {
      serverApolloClient.cache.restore(options.initialState)
    }
    return serverApolloClient
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(options)
  }

  if (options.initialState) {
    apolloClient.cache.restore(options.initialState)
  }

  return apolloClient
}
