import React from 'react'
import { useSystemConfig } from './use-system-config'
import Head from 'next/head'
import { useEffect } from 'react'
import { Router } from 'next/router'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, trackingId: string) => {
  window.gtag('config', trackingId, {
    page_path: url,
  })
}

export function Gtag() {
  const systemConfig = useSystemConfig()
  const gaTrackingId = systemConfig && systemConfig.gaTrackingId

  useEffect(() => {
    if (!gaTrackingId) {
      return
    }
    window.gtag('js', new Date())
    window.gtag('config', gaTrackingId, { anonymize_ip: true })

    Router.events.on('routeChangeComplete', (url) =>
      pageview(url, gaTrackingId)
    )
  }, [gaTrackingId])

  if (!gaTrackingId) {
    return null
  }

  return (
    <Head>
      <script
        key="gtag.js"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
      />
    </Head>
  )
}
